.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, #362706 0%, #464e2e 100%);
  color: white;
}
.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex-lwc {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: fixed;
  width: 100%;
  bottom: 0%;
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* WAVE Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.svg-container {
  position: fixed;
  bottom: 0%;
}
/* FISH FACING RIGHT */
.fish {
  position: absolute;
  height: 33%;
  width: 100px;
  left: -100px;
  bottom: 0px;
  z-index: 2;
  animation: swim 30s linear infinite;
}

.fish2 {
  position: absolute;
  height: 33%;
  width: 150px;
  left: -150px;
  bottom: calc(100% - 50px);
  z-index: 2;
  animation: swim1 30s linear 7.5s infinite;
}

.fish3 {
  position: absolute;
  height: 33%;
  width: 100px;
  left: -100px;
  bottom: 0%;
  z-index: 2;
  animation: swim2 30s linear 15s infinite;
}

.fish4 {
  position: absolute;
  height: 33%;
  width: 200px;
  left: -200px;
  bottom: 0%;
  z-index: 2;
  animation: swim3 30s linear 22.5s infinite;
}
/* FISH FACING LEFT */

.fish5 {
  position: absolute;
  height: 33%;
  width: 100px;
  right: -100px;
  bottom: calc(100% - 40px);
  z-index: 2;
  animation: swim4 30s linear infinite;
}

.fish6 {
  position: absolute;
  height: 50px;
  width: 150px;
  right: -150px;
  bottom: calc(100% - 50px);
  z-index: 2;
  animation: swim5 30s linear 7.5s infinite;
}

.fish7 {
  position: absolute;
  height: 33%;
  width: 100px;
  right: -100px;
  bottom: 0%;
  z-index: 2;
  animation: swim6 30s linear 15s infinite;
}

.fish8 {
  position: absolute;
  height: 33%;
  width: 200px;
  right: -200px;
  bottom: 0%;
  z-index: 2;
  animation: swim7 30s linear 22.5s infinite;
}
/* FISH FACING RIGHT */
@keyframes swim {
  10% {
    transform: translate(10vw, 0%);
  }
  20% {
    transform: translate(20vw, calc(-100% - 30px));
  }

  30% {
    transform: translate(30vw, calc(100% - 50px));
  }

  40% {
    transform: translate(40vw, calc(-100% - 30px));
  }

  50% {
    transform: translate(50vw, calc(100% - 50px));
  }

  60% {
    transform: translate(60vw, calc(-100% - 30px));
  }
  70% {
    transform: translate(70vw, calc(100% - 50px));
  }
  80% {
    transform: translate(80vw, calc(-100% - 30px));
  }
  90% {
    transform: translate(90vw, calc(100% - 50px));
  }

  100% {
    transform: translate(102vw, calc(-100% - 30px));
  }
}
@keyframes swim1 {
  10% {
    transform: translate(10vw, 0%);
  }

  30% {
    transform: translate(30vw, calc(100% + 30px));
  }

  50% {
    transform: translate(50vw, calc(-100% + 50px));
  }

  70% {
    transform: translate(70vw, calc(100% + 30px));
  }

  90% {
    transform: translate(90vw, calc(-100% + 50px));
  }

  100% {
    transform: translate(102vw, calc(100% + 30px));
  }
}
@keyframes swim2 {
  0% {
    transform: translateY(calc(-50% - 25px));
  }
  20% {
    transform: translate(20vw, 0%);
  }

  40% {
    transform: translate(40vw, calc(-100% - 45px));
  }

  60% {
    transform: translate(60vw, 0%);
  }

  80% {
    transform: translate(80vw, calc(-100% - 45px));
  }

  100% {
    transform: translate(102vw, 0%);
  }
}

@keyframes swim3 {
  10% {
    transform: translate(10vw, 0%);
  }

  30% {
    transform: translate(30vw, calc(-100% - 50px));
  }

  50% {
    transform: translate(50vw, calc(100% - 40px));
  }

  60% {
    transform: translate(60vw, calc(-100% - 50px));
  }
  80% {
    transform: translate(80vw, calc(100% - 40px));
  }

  100% {
    transform: translate(102vw, calc(-100% - 50px));
  }
}
/* FISH FACING LEFT */
@keyframes swim4 {
  10% {
    transform: translate(-10vw, 0%);
  }
  20% {
    transform: translate(-20vw, calc(100% + 50px));
  }

  30% {
    transform: translate(-30vw, calc(100% - 50px));
  }

  40% {
    transform: translate(-40vw, calc(100% + 50px));
  }

  50% {
    transform: translate(-50vw, calc(100% - 50px));
  }

  60% {
    transform: translate(-60vw, calc(100% + 50px));
  }
  70% {
    transform: translate(-70vw, calc(100% - 50px));
  }
  80% {
    transform: translate(-80vw, calc(100% + 50px));
  }
  90% {
    transform: translate(-90vw, calc(100% - 50px));
  }

  100% {
    transform: translate(-102vw, calc(100% + 50px));
  }
}

@keyframes swim5 {
  10% {
    transform: translate(-10vw, 0%);
  }

  30% {
    transform: translate(-30vw, calc(100% + 30px));
  }

  50% {
    transform: translate(-50vw, calc(-100% + 50px));
  }

  70% {
    transform: translate(-70vw, calc(100% + 30px));
  }

  90% {
    transform: translate(-90vw, calc(-100% + 50px));
  }

  100% {
    transform: translate(-102vw, calc(100% + 30px));
  }
}

@keyframes swim6 {
  0% {
    transform: translateY(calc(-50% - 25px));
  }
  20% {
    transform: translate(-20vw, 0%);
  }

  40% {
    transform: translate(-40vw, calc(-100% - 45px));
  }

  60% {
    transform: translate(-60vw, 0%);
  }

  80% {
    transform: translate(-80vw, calc(-100% - 45px));
  }

  100% {
    transform: translate(-102vw, 0%);
  }
}

@keyframes swim7 {
  10% {
    transform: translate(-10vw, 0%);
  }

  30% {
    transform: translate(-30vw, calc(-100% - 50px));
  }

  50% {
    transform: translate(-50vw, calc(100% - 40px));
  }

  60% {
    transform: translate(-60vw, calc(-100% - 50px));
  }
  80% {
    transform: translate(-80vw, calc(100% - 40px));
  }

  100% {
    transform: translate(-102vw, calc(-100% - 50px));
  }
}
